import moment from 'moment';
import axios from '@/utils/axios';
const config = CONFIG;
const portalApiDir = config.portalApiDir;

export default  {
    props: {},
    data () {
        return {
            busy: false,
            accessKey: 'none',
        };
    },
    methods: {
        sleep (ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        $accessible (item) {
            if (!item.role && !item) {
                return true;
            }
            return this.$store.getters.accessible(item.role || item);
        },

        /**
         * sends command to server
         *  - returns boolean false when result.error exists unless data.returnResult is true
         * @param {object} data
         * @param {string} data.accessKey
         * @param {boolean} data.returnResult - always return result
         * @returns {Promise<object|false>} false if result has error property
         */
        async sendCommand (data) {
            this.busy = true;
            data.accessKey = this.accessKey;
            let result = await this.$store.dispatch('sendCommand', data);
            console.log('sendCommand result', result);
            this.busy = false;
            if (result.error) {
                if(data.returnResult){
                    return result;
                }
                return false;
            }
            return result;
        },

        async post (url, params) {
            this.busy = true;
            const result = await this.$store.dispatch('post', {url, params});
            this.busy = false;
            if (result.error) {
                return false;
            }
            return result;
        },

        /**
         * Downloads a file from the "portal" routes
         *
         * @param {string} url
         * @param {string} mimeType
         * @returns {blob} downloaded file from server
         */
        async axiosDownload (url, mimeType, onDownloadProgress) {
            let file;
            try {
                const results = await axios.get(`${portalApiDir}/${url}`, {
                    responseType: 'blob',
                    onDownloadProgress
                });
                file = new Blob([results.data], {
                    type: mimeType,
                });
            } catch (err) {
                console.error('axiosDownload error:', url, mimeType, err);
            }
            return file;
        },

        // socket stuff
        socketEmit (msg, data, cb, progressCb) {
            let start = moment();
            let socket = window.socket;
            let callback = cb;
            data = data || {};
            if (progressCb) {
                let pcb = (arg) => {
                    progressCb([arg, 'Elapsed time:', moment().diff(start, 'seconds'), 'seconds'].join(' '));
                };
                socket.on('progress', pcb);
                callback = function () {
                    socket.off('progress', pcb);
                    cb(...arguments);
                };
            }
            socket.emit(msg, data, callback);
        },

        socketOn (msg, handler) {
            let socket = window.socket;
            socket.on(msg, handler);
        },

        leaveSocketRoom (msg, handler) {
            const socket = window.socket;
            socket.leave(msg, handler);
        }
    },

    created () {
        this.axios = axios;
    }
};
